import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import Vuelidate from 'vuelidate'
import VueCurrencyFilter from 'vue-currency-filter'
import VueAnalytics from 'vue-analytics';

Vue.use(VueAnalytics, {
  id: 'UA-64792334-1',
  router
});

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);

import './assets/css/theme.css';


Vue.use(VueCurrencyFilter, {
  symbol : 'R$',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true
})

Vue.use(Vuelidate)

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

var firebase = require('firebase/app');
require('firebase/auth');
require('firebase/firestore');
require('firebase/storage');

Vue.config.productionTip = false

var config = {
  apiKey: "AIzaSyClKWmq6bkv2kW-ZT-G_ESrxzU8b4dIF9w",
  authDomain: 'parceiro-caixa.firebaseapp.com',
  databaseURL: 'https://parceiro-caixa.firebaseio.com',
  projectId: 'parceiro-caixa',
  storageBucket: 'parceiro-caixa.appspot.com',
  messagingSenderId: '338812933996',
  appId: '1:338812933996:web:af25011af63d15957e4549',
  measurementId: 'G-M03FSPXHK5'
};

const firebaseApp = firebase.initializeApp(config);
export default firebaseApp.firestore();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
