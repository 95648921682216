<template>
  <div>
    <b-alert
      class="position-fixed fixed-top m-0 rounded-0 text-center"
      fade
      :show="dismissCountDown"
      :variant="errorMessage.color"
      @dismissed="dismissed"
      >{{ errorMessage.text }}</b-alert
    >
    <div class="teste d-none d-sm-block">
      <div class="teste2">
        <img
          src="@/assets/project_create_illo_welcome@2x.png"
          class="teste3"
          fluid
        />
      </div>
      <!--<div class="text-center text-white infos">
              <small>
                2021 © Parceiro CAIXA. Todos direitos reservados.
              <br>
              Rio de Janeiro, RJ - CNPJ.: 11.689.744/0001-81
              </small>
            </div>-->
    </div>
    <!-- ========== HEADER ========== -->
    <header
      id="header"
      class="header header-box-shadow-on-scroll header-abs-top header-bg-transparent header-show-hide"
      data-hs-header-options='{
            "fixMoment": 1000,
            "fixEffect": "slide"
          }'
    >
      <div class="header-section">
        <!-- Topbar -->
        <div class="container header-hide-content py-2 px-0">
          <div class="d-flex align-items-center">
            <!-- Language -->
            <div class="d-flex align-items-center">
              <div>
                <i
                  class="fas fa-chevron-left p-2 mr-md-5"
                  style="cursor: pointer"
                  v-show="
                    messageStep > 5 &&
                      messageStep < 14 &&
                      !duplicateCpfValidation
                  "
                  @click="handleBack()"
                ></i>
              </div>
              <div>
                <span class="pl-2"
                  >Passo <b style="font-size: 30px">{{ stepCounter }}</b
                  >/ 3 - {{ stepMessage }}
                  <p
                    class="text-muted"
                    style="font-size: 10px; margin: -8px 0 0 10px"
                  >
                    <i class="fas fa-lock pr-1"></i> Ambiente protegido
                  </p></span
                >
              </div>
            </div>
            <!-- End Language -->

            <div class="ml-auto text-white d-none d-sm-block">
              <!-- Helps
              Ambiente protegido <i class="fas fa-lock"></i> -->
              <img src="@/assets/logo.png" alt="Parceiro CAIXA" width="100" />
              <!-- End Helps -->
            </div>
          </div>
        </div>
        <!-- End Topbar -->

        <!--<div id="logoAndNav" class="container">
           Nav 
          <nav class="js-mega-menu navbar navbar-expand-lg">-->
        <!-- Logo 
            <img src="@/assets/logo.png" alt="Parceiro CAIXA" width="100" />-->
        <!-- End Logo 
          </nav>-->
        <!-- End Nav 
        </div>-->
      </div>
    </header>
    <!-- ========== END HEADER ========== -->

    <!-- ========== MAIN CONTENT ========== -->
    <main id="content" role="main">
      <!-- Hero Section -->
      <div class="d-flex position-relative">
        <div
          class="container d-flex justify-content-center align-items-center space-top-2 space-0 min-vh-100"
        >
          <!-- Content -->
          <div class="w-md-100">
            <div class="row">
              <div class="col-lg-9 text-center position-relative">
                <img
                  class="position-absolute"
                  src="@/assets/lock.gif"
                  fluid
                  alt="Parceiro CAIXA"
                  style="
                    top: 50%;
                    height: 50vh;
                    opacity: 90%;
                    z-index: -3;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  "
                  v-show="lockGif"
                />
                <div class="mb-11 mt-11">
                  <transition
                    v-bind:css="false"
                    v-on:before-enter="beforeEnter"
                    v-on:enter="enter"
                    v-on:leave="leave"
                  >
                    <div v-if="show">
                      <h1 class="display-5 mb-5">
                        {{ message }}
                      </h1>
                      <div v-show="messageStep == 5">
                        <b-col cols="12 mt-4">
                          <b-form-group>
                            <b-form-radio-group
                              id="btn-radios-1"
                              class="btn btn-block px-0"
                              :class="{
                                'is-invalid':
                                  $v.acquisitionForecast.$error,
                              }"
                              v-model="acquisitionForecast"
                              :options="acquisitionForecastOptions"
                              buttons
                              stacked
                              size="lg"
                              button-variant="outline-primary"
                              name="acquisitionForecastOptions"
                            ></b-form-radio-group>
                            <b-form-text class="mt-0 float-left"
                              >Selecione uma das opções acima para
                              prosseguir.</b-form-text
                            >
                          </b-form-group>

                          <!--<b-form-select
                    class="mt-3"
                    v-model="acquisitionForecastSelected"
                    :options="acquisitionForecastOptions"
                    size="lg"
                    :class="{
                      'is-invalid': $v.acquisitionForecastSelected.$error,
                    }"
                  ></b-form-select>-->
                          <div
                            v-if="$v.acquisitionForecast.$error"
                            class="invalid-feedback text-left text-left"
                          >
                            <span
                              v-if="!$v.acquisitionForecast.required"
                              >Campo obrigatório.</span
                            >
                          </div>
                        </b-col>
                      </div>

                      <div class="mt-4" v-show="messageStep == 7">
                        <b-row>
                          <b-col lg="6">
                            <!--<blockquote class="blockquote" id="subTitle">
                        <h3 class="display-4-title">Sobre o Imóvel</h3>
                        <p class="mb-0 lead">{{ message }}</p>-->

                            <!--<footer class="blockquote-footer">
                          É o que tem maior renda!
                          <i
                            class="fas fa-question-circle"
                            style="color:orange"
                            data-toggle="popover"
                            title="Proponente Principal"
                            data-content="Se a proposta possui mais de um participante ao crédito, indique o que possui maior renda."
                          ></i>
                        </footer>-->
                            <!--</blockquote>-->
                            <!--<img
                            class="position-relative"
                            src="@/assets/house.png"
                            fluid
                            alt="Parceiro CAIXA"
                          />-->
                          </b-col>
                          <b-col lg="6">
                            <b-row></b-row>
                          </b-col>
                        </b-row>
                        <b-col
                          cols="12 my-1"
                          v-show="acquisitionForecast"
                        >
                          <b-form-group
                            label="Qual o tipo de imóvel pretendido?"
                            class="text-left pb-0"
                          >
                            <b-form-radio-group
                              id="btn-radios-1"
                              class="btn btn-block px-0 pt-0"
                              v-model="propertyTypeSelected"
                              :options="propertyTypeOptions"
                              buttons
                              size="lg"
                              button-variant="outline-primary"
                              name="propertyTypeOptions"
                            ></b-form-radio-group>
                          </b-form-group>
                          <div
                            v-if="$v.propertyTypeSelected.$error"
                            class="invalid-feedback text-left"
                          >
                            <span v-if="!$v.propertyTypeSelected.required"
                              >Campo obrigatório.</span
                            >
                          </div>
                        </b-col>

                        <b-col cols="12 my-1" v-show="propertyTypeSelected">
                          <b-form-group
                            label="Em qual categoria se encontra o imóvel pretendido?"
                            class="text-left pb-0"
                          >
                          <div class="d-none d-sm-block">
                            <b-form-radio-group
                              id="radios-propertyCategory"
                              class="btn btn-block px-0 pt-0"
                              v-model="propertyCategorySelected"
                              :options="propertyCategoryOptions"
                              buttons
                              size="lg"
                              button-variant="outline-primary"
                              name="propertyCategoryOptions"
                            ></b-form-radio-group>
                          </div>
                          <div class="d-block d-sm-none">
                            <b-form-radio-group
                              id="btn-radios-2"
                              class="btn btn-block px-0 pt-0"
                              v-model="propertyCategorySelected"
                              :options="propertyCategoryOptions"
                              buttons
                              stacked
                              size="lg"
                              button-variant="outline-primary"
                              name="propertyCategoryOptions"
                            ></b-form-radio-group>
                          </div>
                          </b-form-group>
                          
                          <!--p: {{ propertyCategorySelected }}-->
                          <!--<b-form-select
                    class="mt-2"
                    v-model="propertyCategorySelected"
                    :options="propertyCategoryOptions"
                    :class="{
                      'is-invalid': $v.propertyCategorySelected.$error,
                    }"
                  ></b-form-select>-->
                          <div
                            v-if="$v.propertyCategorySelected.$error"
                            class="invalid-feedback text-left"
                          >
                            <span v-if="!$v.propertyCategorySelected.required"
                              >Campo obrigatório.</span
                            >
                          </div>
                        </b-col>
                        <b-col cols="12 my-2" v-show="propertyCategorySelected">
                          <label for="input-propertyLocation" class="float-left"
                            >Onde você quer morar?</label
                          >
                          <vue-google-autocomplete
                            ref="address"
                            id="map"
                            classname="form-control form-control-lg"
                            class="mt-1 mr-sm-2 mb-sm-0"
                            :class="{
                              'is-invalid': $v.propertyLocation.$error,
                            }"
                            placeholder="Localização do imóvel pretendido"
                            v-on:placechanged="getAddressData"
                            v-model="propertyLocation"
                            @focus="cnpj = false"
                            @inputChange="handleLocation"
                            @blur="cnpj = true"
                            country="br"
                            types=""
                          >
                            <!-- Inlude: types="(regions)" => https://github.com/olefirenko/vue-google-autocomplete -->
                          </vue-google-autocomplete>

                          <b-form-text class="mt-0 text-left"
                            >Insira o bairro ou rua do imóvel e em seguida
                            selecione entre as opções.</b-form-text
                          >
                          <div
                            v-if="$v.propertyLocation.$error"
                            class="invalid-feedback text-left"
                          >
                            <span v-if="!$v.propertyLocation.required"
                              >Campo obrigatório.</span
                            >
                          </div>
                        </b-col>
                      </div>

                      <div class="mt-4" v-show="messageStep == 8">
                        <b-col cols="12">
                          <label for="input-propertyValue" class="float-left"
                            >Qual o valor do imóvel?</label
                          >
                          <currency-input
                            id="propertyValue"
                            class="form-control form-control-lg"
                            maxlength="15"
                            :class="{ 'is-invalid': $v.propertyValue.$error }"
                            placeholder="Informe o valor de compra e venda"
                            @blur="handleBlur"
                            v-model="propertyValue"
                            currency="BRL"
                            locale="pt"
                            :distraction-free="false"
                            :auto-decimal-mode="true"
                          />
                          <!--<currency-input
                    class="mt-2 form-control"
                    maxlength="8"
                    :class="{ 'is-invalid': $v.propertyValue.$error }"
                    placeholder="Valor do imóvel pretendido"
                    currency="BRL"
                    locale="pt"
                    v-model="propertyValue"
                        />-->
                          <!--
                    :placeholder="propertyValue = 0 ? 0 : 'Valor do Imóvel Pretendido'"
                    -->
                          <div
                            v-if="$v.propertyValue.$error"
                            class="invalid-feedback text-left"
                          >
                            <span
                              v-if="
                                !$v.propertyValue.minLength ||
                                  !$v.propertyValue.required
                              "
                              >Campo obrigatório. Min de 5 caracteres. Insira
                              apenas números.</span
                            >
                          </div>
                          <b-form-text class="mt-0 text-left"
                            >Insira apenas números</b-form-text
                          >
                          <!--p: {{ propertyValue }}-->
                        </b-col>
                        <b-col
                          cols="12"
                          class="mt-3"
                          v-if="showEntranceInput || propertyValue >= 10000"
                        >
                          <label
                            for="input-propertyValue"
                            class="text-left float-left"
                            >Quanto hoje você disponibiliza em recursos próprios
                            (dinheiro + FGTS, se houver) para a entrada?
                            <i
                              class="fas fa-info-circle mt-1 ml-1"
                              style="cursor: pointer"
                              @click="$bvModal.show('entrance-modal-help')"
                            ></i>
                          </label>
                          <currency-input
                            class="form-control form-control-lg"
                            maxlength="15"
                            :class="{ 'is-invalid': $v.entranceValue.$error }"
                            placeholder="Valor de entrada"
                            v-model="entranceValue"
                            currency="BRL"
                            locale="pt"
                            :distraction-free="false"
                            :auto-decimal-mode="true"
                          />
                          <div
                            v-if="$v.entranceValue.$error"
                            class="invalid-feedback text-left"
                          >
                            <span
                              v-if="
                                !$v.entranceValue.minLength ||
                                  !$v.entranceValue.required
                              "
                              >Campo obrigatório. Min de 5 caracteres. Insira
                              apenas números.</span
                            >
                          </div>
                          <b-form-text class="text-left"
                            >Sugerimos
                            {{ (propertyValue * 0.2) | currency }} reais. Que
                            tal?</b-form-text
                          >
                          <b-form-text class="text-left d-none"
                            >Insira apenas números.</b-form-text
                          >
                        </b-col>
                        <b-modal
                          id="entrance-modal-help"
                          centered
                          :hide-header="true"
                        >
                          <div>
                            O valor de recursos próprios é a soma dos recursos
                            em dinheiro que você possui e, se houver, o valor
                            total disponível nas suas contas de FGTS. Geralmente
                            o valor mínimo de entrada é de 20% que nesse caso
                            equivale a
                            {{ (propertyValue * 0.2) | currency }} reais.
                          </div>
                          <template v-slot:modal-footer>
                            <div class="w-100">
                              <b-button
                                variant="primary"
                                size="sm"
                                class="float-right w-25 ml-3"
                                @click="$bvModal.hide('entrance-modal-help')"
                                >Entendi!</b-button
                              >
                            </div>
                          </template>
                        </b-modal>
                        <b-col
                          cols="12"
                          class="mt-4 d-flex flex-row"
                          v-if="showEntranceInput"
                        >
                          <b-form-checkbox
                            id="notaryExpensesChecbox"
                            class="text-left"
                            v-model="notaryExpenses"
                            name="notaryExpensesChecbox"
                          >
                            Desejo incluir despesas cartorárias.
                          </b-form-checkbox>
                          <i
                            class="fas fa-info-circle mt-1 ml-1"
                            style="cursor: pointer"
                            @click="$bvModal.show('notary-expenses-modal-help')"
                          ></i>
                        </b-col>
                      </div>
                      <b-modal
                        id="notary-expenses-modal-help"
                        centered
                        :hide-header="true"
                      >
                        <div>
                          Na compra de um imóvel é necessário pagar o ITBI e os
                          custos do cartório onde a transação será registrada. A
                          CAIXA te dá a opção de incluir um crédito de até 5% do
                          valor de financiamento que esteja dentro da quota
                          máxima permitida e após recebermos o contrato
                          registrado os custos adicionais serão creditados na
                          sua conta corrente via reembolso e os custos serão
                          inclusos no valor final do financiamento
                        </div>
                        <template v-slot:modal-footer>
                          <div class="w-100">
                            <b-button
                              variant="primary"
                              size="sm"
                              class="float-right w-25 ml-3"
                              @click="
                                $bvModal.hide('notary-expenses-modal-help')
                              "
                              >Entendi!</b-button
                            >
                          </div>
                        </template>
                      </b-modal>

                      <!-- INFORMAÇÕES COMPLEMENTARES DA PROPOSTA -->
                      <!-- ADDITIONAL INFORMATION TO THE PROPOSAL -->
                      <div v-show="messageStep == 10">
                        <b-form-text class="mt-0 text-left">
                          Você pode avançar sem marcar a opção caso nenhuma se
                          aplica ao seu caso. ;)</b-form-text
                        >
                        <b-form-checkbox-group
                          class="my-4 text-left"
                          name="checkbox-1"
                          :options="participantsItemsOptions"
                          v-model="selectedItem"
                          stacked
                        >
                        </b-form-checkbox-group>
                      </div>

                      <!-- INFOMAÇÕES DO(S) PROPONENTE(S) -->
                      <!-- INFORMATION OF THE CLIENT(S) -->
                      <div class="pt-4" v-if="messageStep == 12">
                        <b-row>
                          <b-col lg="6">
                            <blockquote class="blockquote" id="subTitle">
                              <p class="mb-0 text-left">{{ message }}</p>
                              <!--<footer class="blockquote-footer">
                                É o que tem maior renda!
                                <i
                                  class="fas fa-question-circle"
                                  style="color:orange"
                                  data-toggle="popover"
                                  title="Proponente Principal"
                                  data-content="Se a proposta possui mais de um participante ao crédito, indique o que possui maior renda."
                                ></i>
                              </footer>-->
                            </blockquote>
                          </b-col>
                          <b-col lg="6">
                            <div
                              v-for="(v, index) in $v.userForm.$each.$iter"
                              :key="index"
                            >
                              <b-row v-if="index == userForm.length - 1">
                                <b-col cols="12 my-1">
                                  <b-input
                                    id="inline-form-input-cpf"
                                    :class="{
                                      'is-invalid':
                                        $v.userForm.$each[currentIndex].cpf
                                          .$error,
                                    }"
                                    placeholder="CPF"
                                    v-mask="['###.###.###-##']"
                                    @blur="duplicityCpfCheck"
                                    v-model.lazy="
                                      $v.userForm.$each[currentIndex].cpf.$model
                                    "
                                  ></b-input>
                                  <div
                                    v-if="
                                      $v.userForm.$each[currentIndex].cpf.$error
                                    "
                                    class="invalid-feedback mt-0 text-left"
                                  >
                                    <span
                                      v-if="
                                        !$v.userForm.$each[currentIndex].cpf
                                          .required
                                      "
                                      >Campo obrigatório.</span
                                    >
                                  </div>
                                </b-col>
                                <b-col cols="12 my-1">
                                  <b-input
                                    id="inline-form-input-name"
                                    :class="{
                                      'is-invalid':
                                        $v.userForm.$each[currentIndex].fullName
                                          .$error,
                                    }"
                                    placeholder="Nome completo"
                                    v-model.lazy="
                                      $v.userForm.$each[currentIndex].fullName
                                        .$model
                                    "
                                  ></b-input>
                                  <div
                                    v-if="
                                      $v.userForm.$each[currentIndex].fullName
                                        .$error
                                    "
                                    class="invalid-feedback mt-0 text-left"
                                  >
                                    <span
                                      v-if="
                                        !$v.userForm.$each[currentIndex]
                                          .fullName.required
                                      "
                                      >Campo obrigatório.</span
                                    >
                                  </div>
                                </b-col>
                                <b-col cols="12 my-1">
                                  <b-input
                                    id="inline-form-input-birthday"
                                    :class="{
                                      'is-invalid':
                                        $v.userForm.$each[currentIndex].birthday
                                          .$error,
                                    }"
                                    placeholder="Data de nascimento"
                                    v-mask="['##/##/####']"
                                    v-model.lazy="
                                      $v.userForm.$each[currentIndex].birthday
                                        .$model
                                    "
                                  ></b-input>
                                  <div
                                    v-if="
                                      $v.userForm.$each[currentIndex].birthday
                                        .$error
                                    "
                                    class="invalid-feedback mt-0 text-left"
                                  >
                                    <span
                                      v-if="
                                        !$v.userForm.$each[currentIndex]
                                          .birthday.required
                                      "
                                      >Campo obrigatório.</span
                                    >
                                  </div>
                                </b-col>
                                <b-col cols="12 my-1">
                                  <div
                                    v-if="
                                      currentIndex > 0
                                        ? userForm[currentIndex - 1]
                                            .maritalStatusField !== 'Casado'
                                          ? true
                                          : false
                                        : true
                                    "
                                  >
                                    <!-- v-model.lazy="
                  $v.userForm.$each[currentIndex].maritalStatusField.$model
                " -->
                                    <!-- :class="{
                  'is-invalid':
                    $v.userForm.$each[currentIndex].maritalStatusField
                      .$error,
                }" -->
                                    <b-form-select
                                      :class="{ 'is-invalid': requiredMarit }"
                                      v-model.trim="
                                        userForm[currentIndex]
                                          .maritalStatusField
                                      "
                                      :options="maritalStatusOptions"
                                      @change="getChange()"
                                    >
                                    </b-form-select>

                                    <!-- <div
                v-if="
                  $v.userForm.$each[currentIndex].maritalStatusField.$error
                "
                class="invalid-feedback"
              > -->
                                    <!-- v-if="
                    !$v.userForm.$each[currentIndex].maritalStatusField
                      .required
                  " -->
                                    <!-- <span
                 v-if="requiredMarit"
                  >Campo obrigatório.</span
                >
              </div> -->
                                  </div>
                                </b-col>
                                <b-col cols="12 my-1">
                                  <div>
                                    <b-form-select
                                      :class="{
                                        'is-invalid':
                                          $v.userForm.$each[currentIndex]
                                            .originOfIncomeSelected.$error,
                                      }"
                                      v-model.trim="
                                        userForm[currentIndex]
                                          .originOfIncomeSelected
                                      "
                                      :options="originOfIncomeOptions"
                                    >
                                    </b-form-select>
                                    <div
                                      v-if="
                                        $v.userForm.$each[currentIndex]
                                          .originOfIncomeSelected.$error
                                      "
                                      class="invalid-feedback mt-0 text-left"
                                    >
                                      <span
                                        v-if="
                                          !$v.userForm.$each[currentIndex]
                                            .originOfIncomeSelected.required
                                        "
                                        >Campo obrigatório.</span
                                      >
                                    </div>
                                  </div>
                                </b-col>
                                <b-col cols="12 my-1">
                                  <b-input
                                    id="inline-form-input-occupation"
                                    :class="{
                                      'is-invalid':
                                        $v.userForm.$each[currentIndex]
                                          .occupation.$error,
                                    }"
                                    placeholder="Profissão"
                                    v-model.lazy="
                                      $v.userForm.$each[currentIndex].occupation
                                        .$model
                                    "
                                  ></b-input>
                                  <div
                                    v-if="
                                      $v.userForm.$each[currentIndex].occupation
                                        .$error
                                    "
                                    class="invalid-feedback mt-0 text-left"
                                  >
                                    <span
                                      v-if="
                                        !$v.userForm.$each[currentIndex]
                                          .occupation.required
                                      "
                                      >Campo obrigatório.</span
                                    >
                                  </div>
                                </b-col>
                                <b-col cols="12 my-1">
                                  <currency-input
                                    class="form-control"
                                    maxlength="15"
                                    :class="{
                                      'is-invalid':
                                        $v.userForm.$each[currentIndex]
                                          .salaryAmount.$error,
                                    }"
                                    placeholder="Valor do salário"
                                    v-model.lazy="
                                      $v.userForm.$each[currentIndex]
                                        .salaryAmount.$model
                                    "
                                    currency="BRL"
                                    locale="pt"
                                    :distraction-free="false"
                                    :auto-decimal-mode="true"
                                  />
                                  <b-form-text class="mt-0 text-left"
                                    >Insira apenas números.</b-form-text
                                  >
                                  <div
                                    v-if="
                                      $v.userForm.$each[currentIndex]
                                        .salaryAmount.$error
                                    "
                                    class="invalid-feedback mt-0 text-left"
                                  >
                                    <span
                                      v-if="
                                        !$v.userForm.$each[currentIndex]
                                          .salaryAmount.required
                                      "
                                      >Campo obrigatório.</span
                                    >
                                  </div>
                                </b-col>
                                <b-col cols="12 my-1">
                                  <b-input
                                    id="inline-form-input-phone"
                                    :class="{
                                      'is-invalid':
                                        $v.userForm.$each[currentIndex]
                                          .phoneNumber.$error,
                                    }"
                                    placeholder="Telefone"
                                    v-mask="[
                                      '(##) ####-####',
                                      '(##) #####-####',
                                    ]"
                                    v-model.lazy="
                                      $v.userForm.$each[currentIndex]
                                        .phoneNumber.$model
                                    "
                                  ></b-input>
                                  <b-form-text
                                    class="mt-0 text-left"
                                    v-show="messageStep == 12"
                                    >Número de contato para o
                                    consultor.</b-form-text
                                  >
                                  <div
                                    v-if="
                                      $v.userForm.$each[currentIndex]
                                        .phoneNumber.$error
                                    "
                                    class="invalid-feedback mt-0 text-left"
                                  >
                                    <span
                                      v-if="
                                        $v.userForm.$each[currentIndex]
                                          .phoneNumber.$error
                                      "
                                      class="invalid-feedback text-left"
                                    ></span>
                                    <span
                                      v-if="
                                        !$v.userForm.$each[currentIndex]
                                          .phoneNumber.required
                                      "
                                      >Campo obrigatório.</span
                                    >
                                  </div>
                                </b-col>
                                <b-col cols="12 my-1 text-left">
                                  <b-input
                                    id="inline-form-input-email"
                                    :class="{
                                      'is-invalid':
                                        $v.userForm.$each[currentIndex].email
                                          .$error,
                                    }"
                                    placeholder="E-mail"
                                    v-model.lazy="
                                      $v.userForm.$each[currentIndex].email
                                        .$model
                                    "
                                  ></b-input>
                                  <b-form-text
                                    class="mt-0 text-left"
                                    v-show="messageStep == 12"
                                    >Não enviamos spam</b-form-text
                                  >
                                  <div
                                    v-if="
                                      $v.userForm.$each[currentIndex].email
                                        .$error
                                    "
                                    class="invalid-feedback mt-0 text-left"
                                  >
                                    <span
                                      v-if="
                                        !$v.userForm.$each[currentIndex].email
                                          .required
                                      "
                                      >Campo obrigatório.</span
                                    >
                                  </div>
                                </b-col>
                                <b-col
                                  cols="12"
                                  class="d-flex py-3"
                                  v-if="
                                    userForm.length > 1 && currentIndex >= 0
                                  "
                                >
                                  <button
                                    class="btn btn-light"
                                    @click="currentIndex = currentIndex - 1"
                                  >
                                    <i
                                      class="fas fa-chevron-left"
                                      data-toggle="popover"
                                      title="Ver anterior"
                                    />
                                  </button>
                                  <button
                                    class="btn btn-light ml-2"
                                    @click="currentIndex = currentIndex + 1"
                                    v-if="currentIndex < userForm.length - 1"
                                  >
                                    <i
                                      class="fas fa-chevron-right"
                                      data-toggle="popover"
                                      title="Próximo"
                                    />
                                  </button>
                                </b-col>

                                <!-- Stable Union Modal -->
                                <b-modal
                                  v-model="showStableUnionPopup"
                                  centered
                                  :hide-header="true"
                                >
                                  <div class="text-center">
                                    Cliente possui ou mantém união estável?
                                  </div>
                                  <template v-slot:modal-footer>
                                    <div class="w-100">
                                      <b-button
                                        variant="primary"
                                        size="sm"
                                        class="float-right w-25 ml-3"
                                        @click="yesStable"
                                        >Sim</b-button
                                      >
                                      <b-button
                                        variant="info"
                                        size="sm"
                                        class="float-right w-25"
                                        @click="noStable"
                                        >Não</b-button
                                      >
                                    </div>
                                  </template>
                                </b-modal>
                                <!-- Add Participant Modal -->
                                <b-modal
                                  v-model="addParticipantPopup"
                                  centered
                                  :hide-header="true"
                                >
                                  <div class="text-center">
                                    Você pode somar a sua renda com a de mais
                                    uma pessoa para pagamento do financiamento,
                                    lembre-se que ela entrará como participante
                                    no contrato e que terá o mesmo direito ao
                                    imóvel que você. Deseja adicionar outro
                                    participante?
                                  </div>
                                  <template v-slot:modal-footer>
                                    <div class="w-100">
                                      <b-button
                                        variant="primary"
                                        size="sm"
                                        class="float-right w-25 ml-3"
                                        @click="addParticipantYes"
                                        >Sim</b-button
                                      >
                                      <b-button
                                        variant="info"
                                        size="sm"
                                        class="float-right w-25"
                                        @click="addParticipantNo"
                                        >Não</b-button
                                      >
                                    </div>
                                  </template>
                                </b-modal>
                              </b-row>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                      <!-- Termos e condições -->
                      <!-- Terms and conditions -->
                      <div class="pt-4" v-if="messageStep == 13">
                        <b-row>
                          <!-- DROPZONE -->
                          <div class="container-fluid py-3">
                            <div class="row justify-content-md-center">
                              <div class="col-md-6 col-sm-12 text-left">
                                <h4>
                                  Que tal agilizar a aprovação do seu crédito já
                                  nos enviando a documentação necessária?
                                </h4>
                                <b-form-text class=""
                                  >Ou se preferir ou não possuir a documentação
                                  digitalizada nesse momento você também poderá
                                  enviar-nos depois. ; )</b-form-text
                                >
                                <ul
                                  class="text-left mt-3"
                                  style="padding-left: 20px; font-size: 14px;"
                                >
                                  <li>Documento de identificação;</li>
                                  <li>Comprovante de CPF;</li>
                                  <li>Certidão de estado civil;</li>
                                  <li>Comprovante de residência;</li>
                                  <li>3 últimos contracheques;</li>
                                  <li>
                                    Declaração do IR com o respectivo recibo de
                                    entrega, se houver;
                                  </li>
                                </ul>
                              </div>

                              <div
                                class="col-md-6 col-sm-12 d-flex align-items-center"
                                v-if="!hideAndShowDropzoneSelected"
                              >
                                <div class="mt-2 mb-2 d-flex flex-column w-100">
                                  <b-form-group>
                                    <b-form-radio-group
                                      id="btn-radios-1"
                                      class="btn btn-block px-0"
                                      v-model="hideAndShowDropzoneSelected"
                                      :options="hideAndShowDropzoneOptions"
                                      buttons
                                      stacked
                                      size="lg"
                                      button-variant="outline-primary"
                                      name="hideAndShowDropzoneOptions"
                                    ></b-form-radio-group>
                                    <b-form-text class="mt-0 float-left"
                                      >Selecione uma das opções acima para
                                      prosseguir.</b-form-text
                                    >
                                  </b-form-group>
                                  <!--<a
                                      @click="ShowAndHideDropzone = true"
                                      class="btn btn-primary btn-block transition-3d-hover text-white"
                                      >Enviar Agora</a
                                    >
                                    <a
                                      @click="ShowAndHideDropzone = f"
                                      class="btn btn-secondary btn-block transition-3d-hover text-white"
                                      >Vou enviar depois</a
                                    >-->
                                  <a
                                    href="#"
                                    class="text-center"
                                    @click="
                                      $bvModal.show('documents-modal-list')
                                    "
                                    >Por que enviar meus documentos?</a
                                  >
                                  <b-modal
                                    id="documents-modal-list"
                                    centered
                                    :hide-header="true"
                                  >
                                    <div>
                                      A efetiva aprovação do seu crédito só é
                                      possível com após a análise dos documentos
                                      enviados e a inclusão das informações
                                      pertinentes ao sistema.
                                      <br />
                                      O envio da documentação é importante para
                                      que possamos retornar com a resposta da
                                      avaliação do sistema referente a aprovação
                                      do crédito.
                                      <br />
                                    </div>
                                    <template v-slot:modal-footer>
                                      <div class="w-100">
                                        <b-button
                                          variant="primary"
                                          size="sm"
                                          class="float-right w-25 ml-3"
                                          @click="
                                            $bvModal.hide(
                                              'documents-modal-list'
                                            )
                                          "
                                          >Entendi!</b-button
                                        >
                                      </div>
                                    </template>
                                  </b-modal>
                                </div>
                              </div>
                              <div class="col-md-6 col-sm-12" v-else>
                                <div class="mt-2 mb-2">
                                  <div
                                    class="dropzone"
                                    @click="$refs.file.click()"
                                    @drop.prevent="onFileChange"
                                    @dragover.prevent
                                  >
                                    <div class="fallback d-none">
                                      <input
                                        name="file"
                                        type="file"
                                        ref="file"
                                        accept=".pdf, .jpg"
                                        @change="onFileChange"
                                        multiple="multiple"
                                      />
                                    </div>

                                    <div class="dz-message needsclick">
                                      <i
                                        class="h1 text-warning fa fa-cloud-upload-alt"
                                      ></i>
                                      <h3>
                                        Solte os arquivos aqui ou clique para
                                        adicionar.
                                      </h3>
                                    </div>
                                  </div>

                                  <div
                                    v-if="file.length"
                                    id="uploadPreviewTemplate"
                                    class="mt-3"
                                  >
                                    <div
                                      class="card mt-1 mb-0 shadow-none border"
                                      v-for="(thumb, index) in file"
                                      :key="index"
                                    >
                                      <div class="px-2">
                                        <div class="row align-items-center">
                                          <div class="col-auto mute">
                                            <div>
                                              <i
                                                class="fa fa-file-pdf thumb-size-30 mr-2"
                                                v-if="isPdf(thumb)"
                                              ></i>
                                              <i
                                                class="fa fa-file-image thumb-size-30 mr-2"
                                                v-if="!isPdf(thumb)"
                                              ></i>
                                              <span>{{ thumb.name }}</span>
                                            </div>
                                          </div>
                                          <div class="col pl-0">
                                            <a
                                              href="javascript:void(0);"
                                              class="text-muted font-weight-bold"
                                              data-dz-name
                                            ></a>
                                            <p class="mb-0" data-dz-size></p>
                                          </div>
                                          <div class="col-auto">
                                            <a
                                              class="btn btn-link btn-lg text-muted"
                                              data-dz-remove
                                              @click="removeItem(index)"
                                            >
                                              <i class="fa fa-times"></i>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <b-form-text class="mt-0 float-left">
                                  Você pode prosseguir com toda, parte ou
                                  nenhuma documentação anexada.</b-form-text
                                >
                              </div>
                            </div>
                          </div>
                          <!-- END DROPZONE -->

                          <div class="container-fluid py-3">
                            <div class="row justify-content-md-center">
                              <div class="col-sm-12"><hr /></div>
                            </div>
                          </div>

                          <!-- MESSAGE -->
                          <div
                            class="container-fluid py-3"
                            v-show="hideAndShowDropzoneSelected != null"
                          >
                            <div class="row justify-content-md-center">
                              <div
                                class="col-md-6 col-sm-12 col-lg-6 text-left"
                              >
                                <h5>Deseja incluir alguma observação?</h5>
                                <b-form-text class=""
                                  >Alguma agência de preferência? Está tratando
                                  com alguma imobiliária? Qual? Fique a vontade
                                  para escrever ou prossiga sem comentários para
                                  enviar.</b-form-text
                                >
                              </div>
                              <div class="col-md-6 col-sm-12 col-lg-6">
                                <div>
                                  <b-form-textarea
                                    placeholder="Escreva aqui..."
                                    size="md"
                                    rows="3"
                                    v-model="comments"
                                  ></b-form-textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- END MESSAGE -->

                          <b-col
                            md="12"
                            v-show="hideAndShowDropzoneSelected != null"
                          >
                            <div
                              class="shadow-sm p-3 mt-5 mb-2 bg-white rounded"
                              style="border: 1px solid #dee2e6; font-size: 14px;"
                            >
                              Credenciados CAIXA e autorizados BACEN ajudamos
                              você desde o correto e melhor enquadramento de
                              taxas que variam de 4,5% a.a. para modalidade
                              sociais até 8,0% e no melhor prazo que pode chegar
                              até 420 meses até a conclusão da proposta do seu
                              financiamento imobiliário CAIXA. Trabalhamos de
                              forma transparente com nossos clientes e
                              disponibizamos o link direto para você também pode
                              realizar a simulação pelo
                              <a
                                href="http://www8.caixa.gov.br/siopiinternet-web/simulaOperacaoInternet.do?method=inicializarCasoUso/"
                                >Simulador CAIXA.</a
                              >
                            </div>
                            <div
                              class="shadow-sm p-3 my-5 bg-white rounded"
                              style="border: 1px solid #dee2e6; font-size: 14px;"
                            >
                              Ao avançar, declaro que li e estou ciente dos
                              <a href="#">termos e condições</a> do site e
                              autorizo a CAIXA a entrar em contato via e-mail
                              e/ou telefone e consultar meus dados no Sistema de
                              Informação de Crédito (SCR) do Banco Central.
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                      <!-- End terms and conditions -->
                      <!-- FINISH MESSAGE -->
                      <div class="pt-4" v-if="messageStep == 15">
                        <p class="lead">
                          Ahhh! Caso não localize a proposta na caixa de entrada
                          do seu e-mail pode ser que tenha ido direto para caixa
                          de spam ou lixo eletrônico.
                        </p>
                        <p class="lead">Não deixe de verificar lá! ; )</p>
                      </div>
                      <!-- END FINISH MESSAGE -->
                    </div>
                  </transition>
                  <!-- <div class="pre-footer">
                    <div class="container-fluid">
                      <b-row
                        class="justify-content-center"
                        v-if="
                          messageStep == 3 ||
                          (showEntranceInput && messageStep == 8) ||
                          messageStep == 10 ||
                          messageStep == 12 ||
                          messageStep == 13
                        "
                      >
                        <b-col cols="12" md="12" lg="9" class="text-center">
                          <a class="btn btn-outline-light mt-2" @click="handleBack">Voltar</a>
                          <div class="py-2 border-top border-secondary">
                            <a
                              @click="handleSubmit"
                              class="btn btn-primary btn-block text-white"
                              >Avançar</a
                            >
                            <a @click="stop ? true : false; show = false" class="btn btn-outline-light mt-2">Avançar</a>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div> -->
                </div>

                <!--<a class="btn btn-primary btn-wide transition-3d-hover" href="../pages/login-simple.html">Get Started</a>
              <a class="btn btn-link btn-wide" href="#">Learn More <i class="fas fa-angle-right fa-sm ml-1"></i></a>-->
              </div>
            </div>
          </div>
          <!-- End Content -->

          <!-- SVG Shape
          <figure
            class="col-lg-7 col-xl-6 d-none d-lg-block position-absolute top-0 right-0 pr-0 ie-main-hero"
            style="margin-top: 6.75rem"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1137.5 979.2"
            >
              <path
                fill="#F9FBFF"
                d="M565.5,957.4c81.1-7.4,155.5-49.3,202.4-115.7C840,739.8,857,570,510.7,348.3C-35.5-1.5-4.2,340.3,2.7,389
              c0.7,4.7,1.2,9.5,1.7,14.2l29.3,321c14,154.2,150.6,267.8,304.9,253.8L565.5,957.4z"
              />
              <defs>
                <path
                  id="mainHeroSVG1"
                  d="M1137.5,0H450.4l-278,279.7C22.4,430.6,24.3,675,176.8,823.5l0,0C316.9,960,537.7,968.7,688.2,843.6l449.3-373.4V0z"
                />
              </defs>
              <clipPath id="mainHeroSVG2">
                <use xlink:href="#mainHeroSVG1" />
              </clipPath>
              <g transform="matrix(1 0 0 1 0 0)" clip-path="url(#mainHeroSVG2)">
                <image
                  width="750"
                  height="750"
                  xlink:href="../assets/img/750x750/img2.jpg"
                  transform="matrix(1.4462 0 0 1.4448 52.8755 0)"
                ></image>
              </g>
            </svg>
          </figure> -->
          <!-- End SVG Shape -->
        </div>
        <!-- ========== FOOTER ========== -->
        <footer class="footer">
          <div class="container">
            <!-- <div class="space-top-2">
              <div class="row justify-content-lg-between">
                <div class="col-lg-3 ml-lg-auto mb-5 mb-lg-0">
                  
                </div>

              </div>
            </div> -->
            <b-row
              class="justify-content-md-center justify-content-lg-start"
              v-if="
                //messageStep == 3 ||
                (showEntranceInput && messageStep == 8) ||
                  messageStep == 10 ||
                  messageStep == 12 ||
                  (messageStep == 13 && hideAndShowDropzoneSelected != null)
              "
            >
              <b-col cols="12" md="12" lg="9" class="text-center">
                <!--<a class="btn btn-outline-light mt-2" @click="handleBack">Voltar</a>-->
                <div class="py-2 border-top border-secondary">
                  <b-button
                    v-if="messageStep != 13"
                    :disabled="duplicateCpfValidation"
                    @click="handleSubmit"
                    class="btn-block text-white"
                    variant="primary"
                    >Avançar</b-button
                  >
                  <a
                    id="formSendAndGoogleAdsConvertion"
                    v-else-if="messageStep == 13"
                    @click="handleSubmit"
                    :disabled="loadingBtn"
                    :class="
                      loadingBtn
                        ? 'btn btn-danger btn-block text-white disabled'
                        : 'btn btn-primary btn-block text-white'
                    "
                  >
                    <span v-if="!loadingBtn"> Confirmar e enviar</span>
                    <span v-else
                      ><span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Envio suspenso...</span
                    >
                  </a>

                  <!--<a @click="stop ? true : false; show = false" class="btn btn-outline-light mt-2">Avançar</a>-->
                </div>
              </b-col>
            </b-row>
            <b-row
              class="justify-content-md-center justify-content-lg-start py-1"
              v-show="cnpj || (!cnpj && propertyLocation)"
              v-else
            >
              <b-col cols="12" md="12" lg="9" class="text-center">
                <div class="text-secondary">
                  <small>
                    2021 - Parceiro CAIXA. Rio de
                    Janeiro, RJ - CNPJ.: 11.689.744/0001-81
                  </small>
                </div>
              </b-col>
            </b-row>
          </div>
        </footer>
        <!-- ========== END FOOTER ========== -->
      </div>
      <!-- End Hero Section -->
    </main>
    <!-- ========== END MAIN CONTENT ========== -->
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import VueRecaptcha from "vue-recaptcha";
import { mask } from "vue-the-mask";
import { CurrencyInput } from "vue-currency-input";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { cpf } from "cpf-cnpj-validator";
import db from "../main";

var bootstrap = require("bootstrap");
var bootbox = require("bootbox");

var firebase = require("firebase/app");

export default {
  name: "Zypt",
  data() {
    return {
      lockGif: true,
      show: false,
      fadeInDuration: 1500,
      fadeOutDuration: 1500,
      /*maxFadeDuration: 10000,*/
      stop: false,
      stepMessage: "Estabelecendo módulo de segurança",
      stepCounter: 0,
      message: "Olá!",
      messageStep: 0,
      showEntranceInput: false,
      name: "",
      loadingBtn: false,
      duplicateCpfValidation: false,
      acquisitionForecast: null,
      acquisitionForecastOptions: [
        //{ value: null, text: "Selecione uma opção" },
        { value: "a", text: "Sim!" },
        { value: "b", text: "Ainda não, mas pretendo em 6 meses" },
        { value: "c", text: "Só estou analisando a minha possibilidade" },
      ],
      participantsItemsOptions: [
        { text: "Tem pretenção de utilizar o FGTS.", value: "a" },
        { text: "Possui imóvel na cidade do imóvel pretendido.", value: "b" },
        {
          text:
            "Tem 36 meses de trabalho sob regime do FGTS, somado todos os períodos trabalhados sendo consecutivos ou não de FGTS.",
          value: "c",
        },
        { text: "Tem mais de um comprador ou dependente.", value: "d" },
        { text: "É servidor público", value: "e" },
        {
          text: "Tem ou gostaria de ter relacionamento com a CAIXA.",
          value: "f",
        },
        {
          text: "Tem ou gostaria de ter crédito salário na CAIXA.",
          value: "g",
        },
      ],
      hideAndShowDropzoneSelected: null,
      hideAndShowDropzoneOptions: [
        { text: "Enviar Agora.", value: true },
        { text: "Enviar Depois.", value: false },
      ],
      selectedItem: [],
      propertyLocation: "",
      //address: '',
      propertyValue: null,
      entranceValue: null,
      notaryExpenses: false,
      propertyTypeSelected: null,
      propertyTypeOptions: [
        //{ value: null, text: "Selecione o tipo do imóvel" },
        { value: "Residencial", text: "Residencial" },
        { value: "Comecial", text: "Comercial" },
      ],
      propertyCategorySelected: null,
      propertyCategoryOptions: [
        //{ value: null, text: "Selecione a categoria do imóvel" },
        { value: "Novo", text: "Imóvel Novo" },
        { value: "Usado", text: "Imóvel Usado" },
        { value: "Adjudicado CAIXA", text: "Imóvel CAIXA" },
        { value: "Terreno e Construção", text: "Aquisição de Terreno/construção" },
      ],
      originOfIncomeOptions: [
        { value: null, text: "Informe o tipo de renda" },
        { value: "Assalariado", text: "Assalariado" },
        { value: "Aposentado/Pensionista", text: "Aposentado/Pensionista" },
        { value: "Autônomo", text: "Autônomo" },
        { value: "Profissional liberal", text: "Profissional liberal" },
        { value: "Sócio proprietário", text: "Sócio proprietário" },
      ],
      maritalStatusOptions: [
        { value: null, text: "Informe o estado civil" },
        { value: "Solteiro", text: "Solteiro" },
        { value: "Casado", text: "Casado" },
        { value: "Divorciado/Separado", text: "Divorciado/Separado" },
        { value: "Viúvo", text: "Viúvo" },
      ],
      allowedTypes: [".pdf", ".jpg"],
      userForm: [
        {
          cpf: "",
          fullName: "",
          birthday: "",
          maritalStatusField: null,
          occupation: "",
          originOfIncomeSelected: null,
          salaryAmount: null,
          phoneNumber: "",
          email: "",
        },
      ],
      currentIndex: 0,
      requiredMarit: false,
      showStableUnionPopup: false,
      stableUnionArray: [],
      showStableUnionPopup: false,
      addParticipantPopup: false,
      // headerHeading: "Diga-me sobre o proponente principal ao crédito. No caso é o que tem maior renda.",
      validStep: false,
      comments: "",
      cnpj: true,
    };
  },
  components: { VueRecaptcha, CurrencyInput, VueGoogleAutocomplete },
  directives: { mask },
  validations: {
    // name: { required, minLength: minLength(4) },
    acquisitionForecast: { required },
    propertyTypeSelected: { required },
    propertyCategorySelected: { required },
    propertyValue: { required, minLength: minLength(5) },
    entranceValue: { required, minLength: minLength(5) },
    propertyLocation: { required, minLength: minLength(5) },
    // selectedItem: { required },
    userForm: {
      required,
      minLength: minLength(1),
      $each: {
        cpf: { required, minLength: minLength(14) },
        fullName: { required },
        birthday: { required },
        salaryAmount: { required },
        originOfIncomeSelected: { required },
        occupation: { required },
        phoneNumber: { required, minLength: minLength(14) },
        email: { required, email },
      },
    },
  },
  mounted() {
    //this.show = true
    this.hideGif();
  },
  watch: {
    acquisitionForecast: function() {
        this.$v.acquisitionForecast.$touch();
        if (this.$v.acquisitionForecast.$invalid) {
          return;
        } else {
          this.setAcquisitionForecast(this.acquisitionForecast);
          //this.messageStep ++
          this.stop = false;
          this.show = false;
        }
    },
    propertyLocation: function() {
      //this.messageStep++
      this.stop = false;
      this.show = false;
    },
  },
  computed: {
    ...mapState([
      "user",
      "proposal",
      "file",
      "errorMessage",
      "dismissCountDown",
    ]),
  },
  methods: {
    ...mapMutations([
      "setFile",
      "removeFile",
      "setErrorMessage",
      "setUserProposals",
      "setParticipantArray",
      "setPropertyDetails",
      "setParticipantItems",
      "setAcquisitionForecast",
      "setIntendedFinancing",
      "setComment",
    ]),
    ...mapActions(["createProposal", "dismissed", "anonymouslySignIn"]),
    hideGif() {
      setTimeout(() => {
        this.stepMessage = "Introdução.";
        this.lockGif = false;
        //this.fadeOutDuration = 500
        this.show = true;
      }, 6840); //6840
    },
    handleBlur(e) {
      if (this.propertyValue >= 10000) {
        this.showEntranceInput = true;
      }
    },
    handleLocation() {
      window.scrollTo({
        bottom: 0,
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    },
    async duplicityCpfCheck(e) {
      //if(this.$v.userForm.$each[this.currentIndex].cpf.$model == '000.000.000-00'){
      //  alert('asdasd')
      //}

      try {
        await this.anonymouslySignIn();
        const snapshotProposal = await db
          .collection("proposals")
          .where(
            "cpf",
            "==",
            this.$v.userForm.$each[this.currentIndex].cpf.$model
          )
          .where("steps.contract.situation", "==", "")
          .limit(1)
          .get();

        if (!snapshotProposal.empty) {
          this.duplicateCpfValidation = true;
          bootbox.alert({
            title: "Ops... : )",
            message:
              "Localizamos uma proposta existente para esse CPF. Entre em contato com o seu consultor ou envie-nos um e-mail para prosseguirmos com a sua proposta: habitacao@parceirocaixa.com.br. ; )",
            centerVertical: true,
            locale: "pt",
            buttons: {
              ok: {
                label: "Entendi!",
              },
            },
          });
        } else {
          this.duplicateCpfValidation = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // GOOGLE API LOCATION
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    getAddressData: function(addressData, placeResultData) {
       const sublocalityArr = placeResultData.address_components.filter((el) =>
        el.types.includes("sublocality")
      );
      const administrative_area_level_2Arr = placeResultData.address_components.filter(
        (el) => el.types.includes("administrative_area_level_2")
      );
      const administrative_area_level_1Arr = placeResultData.address_components.filter(
        (el) => el.types.includes("administrative_area_level_1")
      );
      this.propertyLocation = {
        sublocality:
          sublocalityArr && sublocalityArr.length
            ? sublocalityArr[0].long_name
            : "",
        administrative_area_level_2:
          administrative_area_level_2Arr &&
          administrative_area_level_2Arr.length
            ? administrative_area_level_2Arr[0].long_name
            : "",
        administrative_area_level_1:
          administrative_area_level_1Arr &&
          administrative_area_level_1Arr.length
            ? administrative_area_level_1Arr[0].long_name
            : "",
        country: addressData.country ? addressData.country : "",
        latitude: addressData.latitude ? addressData.latitude : "",
        locality: addressData.locality ? addressData.locality : "",
        longitude: addressData.longitude ? addressData.longitude : "",
        postal_code: addressData.postal_code ? addressData.postal_code : "",
        route: addressData.route ? addressData.route : "",
     }
    },
    beforeEnter: function(el) {
      el.style.opacity = 0;
    },
    enter: function(el, done) {
      var vm = this;
      Velocity(
        el,
        { opacity: 1 },
        {
          duration: this.fadeInDuration,
          complete: function() {
            done();
            if (!vm.stop) vm.show = false;
          },
        }
      );
    },
    leave: function(el, done) {
      var vm = this;
      Velocity(
        el,
        { opacity: 0 },
        {
          duration: this.fadeOutDuration,
          complete: function() {
            done();
            vm.show = true;
            if (vm.messageStep == 0) {
              vm.stop = true;
              vm.message =
                "Sou a Zypt, sua assistente nesse atendimento virtual.";
              vm.messageStep++;
              setTimeout(() => {
                vm.show = false;
              }, 4000);
            } else if (vm.messageStep == 1) {
              vm.stop = true;
              vm.message =
                "Juntos criaremos sua proposta como se estivesse na agência! ;)";
              setTimeout(() => {
                vm.show = false;
              }, 5000);
              vm.messageStep++;
            } else if (vm.messageStep == 2) {
              vm.stop = true;
              vm.message =
                "Em seguida um consultor especializado entrará em contato.";
              vm.messageStep++;
              setTimeout(() => {
                vm.show = false;
              }, 5000);
            } else if (vm.messageStep == 3) {
              vm.stop = false;
              vm.message = `Vamos lá! : )`;
              vm.messageStep++;
            } else if (vm.messageStep == 4) {
              vm.stepMessage = "Sobre o imóvel";
              vm.stepCounter = 1;
              vm.message =
                "Você já possui algum imóvel em vista para aquisição?";
              vm.messageStep++;
              vm.stop = true;
            } else if (vm.messageStep == 5) {
              vm.message = "Vamos entender melhor seu objetivo.";
              vm.messageStep++;
              vm.stop = false;
            } else if (vm.messageStep == 6) {
              vm.message = "Diga-me sobre o imóvel pretendido.";
              vm.messageStep++;
              vm.stop = true;
            } else if (vm.messageStep == 7) {
              vm.message = "Diga-nos sobre os valores pretendidos.";
              vm.messageStep++;
              vm.stop = true;
            } else if (vm.messageStep == 8) {
              vm.stepMessage = "Entendendo o perfil do(s) participante(s).";
              vm.stepCounter = 2;
              vm.stop = true;
              vm.message =
                "Os dados solicitados agora nos ajudará a encontrar a melhor taxa para você. Vamos lá!";
              vm.messageStep++;
              setTimeout(() => {
                vm.show = false;
              }, 6000);
            } else if (vm.messageStep == 9) {
              vm.message =
                "Selecione o que se aplica a pelo menos 1 do(s) parcitipante(s).";
              vm.messageStep++;
              vm.stop = true;
            } else if (vm.messageStep == 10) {
              vm.stepMessage = "Cadastro do(s) participante(s)";
              vm.stepCounter = 3;
              vm.stop = true;
              vm.message =
                "Nessa parte final vamos realizar um pequeno cadastro necessário para entender melhor com os dados solicitados o perfil da proposta.";
              vm.messageStep++;
              setTimeout(() => {
                vm.show = false;
              }, 7000);
            } else if (vm.messageStep == 11) {
              vm.message =
                "Diga-me sobre o proponente principal ao crédito. No caso é o que tem maior renda.";
              vm.messageStep++;
              vm.stop = true;
            }
          },
        }
      );
    },
    handleSubmit() {
      //this.submituited = true;
      //alert()

      if (this.messageStep == 3) {
        this.$v.name.$touch();
        if (this.$v.name.$invalid) {
          return;
        } else {
          //this.messageStep ++
          this.stop = false;
          this.show = false;
        }
      } else if (this.messageStep == 5) {
        this.$v.acquisitionForecast.$touch();
        if (this.$v.acquisitionForecast.$invalid) {
          return;
        } else {
          this.setAcquisitionForecast(this.acquisitionForecast);
          //this.messageStep ++
          this.stop = false;
          this.show = false;
        }
      } else if (this.messageStep == 8) {
        this.$v.propertyTypeSelected.$touch();
        this.$v.propertyCategorySelected.$touch();
        this.$v.propertyValue.$touch();
        this.$v.propertyLocation.$touch();
        this.$v.entranceValue.$touch();
        if (
          this.$v.propertyTypeSelected.$invalid ||
          this.$v.propertyCategorySelected.$invalid ||
          this.$v.propertyValue.$invalid ||
          this.$v.propertyLocation.$invalid ||
          this.$v.entranceValue.$invalid
        ) {
          return;
        } else {
          //this.messageStep ++
          let intendedFinancingValue = this.propertyValue - this.entranceValue;
          //let notaryExpenses = this.notaryExpenses;
          let propertyDetails = {
            propertyTypeSelected: this.propertyTypeSelected,
            propertyCategorySelected: this.propertyCategorySelected,
            propertyValue: this.propertyValue,
            notaryExpenses: this.notaryExpenses,
            propertyLocation: {
              administrative_area_level_1: this.propertyLocation
                .administrative_area_level_1
                ? this.propertyLocation.administrative_area_level_1
                : "",
              administrative_area_level_2: this.propertyLocation
                .administrative_area_level_2
                ? this.propertyLocation.administrative_area_level_2
                : "",
              country: this.propertyLocation.country
                ? this.propertyLocation.country
                : "",
              latitude: this.propertyLocation.latitude
                ? this.propertyLocation.latitude
                : "",
              longitude: this.propertyLocation.longitude
                ? this.propertyLocation.longitude
                : "",
              postal_code: this.propertyLocation.postal_code
                ? this.propertyLocation.postal_code
                : "",
              route: this.propertyLocation.route
                ? this.propertyLocation.route
                : "",
            },
          };
          this.setPropertyDetails(propertyDetails);
          this.setIntendedFinancing(intendedFinancingValue);
          this.stop = false;
          this.show = false;
        }
      }
      //else if (this.messageStep == 8) {
      //  this.$v.entranceValue.$touch();
      //  if (!this.$v.entranceValue.$invalid) {
      //    let intendedFinancingValue = this.propertyValue - this.entranceValue;
      //    this.setIntendedFinancing(intendedFinancingValue);
      //    this.stop = false;
      //    this.show = false;
      //  }
      //}
      else if (this.messageStep == 10) {
        // this.$v.selectedItem.$touch();
        // if (!this.$v.selectedItem.$invalid) {
        this.setParticipantItems(this.selectedItem);
        this.stop = false;
        this.show = false;
        // }
      } else if (this.messageStep == 12) {
        // const num = cpf.generate();
        // console.log(num);
        this.$v.$touch();
        if (
          this.currentIndex > 0 &&
          this.userForm[this.currentIndex - 1].maritalStatusField ==
            "Casado" &&
          this.userForm[this.currentIndex].maritalStatusField != null
        ) {
          if (
            this.userForm[this.userForm.length - 1].maritalStatusField !==
            "Casado"
          ) {
            if (this.userForm.length == 1) this.showStableUnionPopup = true;
            if (
              this.userForm.length > 1 &&
              this.stableUnionArray[this.userForm.length - 2] == "participant"
            ) {
              this.showStableUnionPopup = true;
            } else if (
              this.userForm.length > 1 &&
              this.stableUnionArray[this.userForm.length - 2] != "participant"
            ) {
              this.addParticipantPopup = true;
            }
          } else {
            this.message = "Informe os dados do(a) cônjuge.";
            this.stableUnionArray.push("married");
            this.addBlankUser();
            this.userForm[this.currentIndex].marriedWith = {
              name: this.userForm[this.currentIndex - 1].fullName,
              cpf: this.userForm[this.currentIndex - 1].cpf
           }
          }
        } else if (
          (this.currentIndex == 0 && !this.userForm[0].maritalStatusField) ||
          // (this.currentIndex > 0 &&
          //   this.userForm[this.currentIndex - 1].maritalStatusField !=
          //     "Casado" &&
          //   this.currentIndex > 1 &&
          //   this.userForm[this.currentIndex - 2].maritalStatusField ==
          //     "Casado" &&
          //   this.userForm[this.currentIndex].maritalStatusField == null)
          (this.currentIndex > 0 &&
            this.userForm[this.currentIndex - 1].maritalStatusField !=
              "Casado" &&
            this.userForm[this.currentIndex].maritalStatusField == null) ||
          (this.currentIndex > 0 &&
            !this.userForm[this.currentIndex - 1].maritalStatusField &&
            !this.userForm[this.currentIndex].maritalStatusField)
        ) {
          this.requiredMarit = true;
          this.$store.commit("setErrorMessage", {
            color: "danger",
            text: "Ops... Corrija os dados necessários para prosseguir.",
          });
        } else if (this.$v.$invalid) {
          this.$store.commit("setErrorMessage", {
            color: "danger",
            text: "Ops... Corrija os dados necessários para prosseguir.",
          });
        } else if (
          cpf.isValid(this.$v.userForm.$each[this.currentIndex].cpf.$model) ===
          false
        ) {
          this.$store.commit("setErrorMessage", {
            color: "danger",
            text: "Ops...CPF Inválido.",
          });
        } else {
          if (
            this.userForm[this.userForm.length - 1].maritalStatusField !==
            "Casado"
          ) {
            if (this.userForm.length == 1) {
              if (!this.validStep) {
                this.showStableUnionPopup = true;
              }
            }
            if (
              this.userForm.length > 1 &&
              this.stableUnionArray[this.userForm.length - 2] == "participant"
            ) {
              this.showStableUnionPopup = true;
            } else if (
              this.userForm.length > 1 &&
              this.stableUnionArray[this.userForm.length - 2] != "participant"
            ) {
              if (
                !this.validStep &&
                !this.$v.userForm.$each[this.currentIndex].cpf.$invalid &&
                !this.$v.userForm.$each[this.currentIndex].fullName.$invalid &&
                !this.$v.userForm.$each[this.currentIndex].birthday.$invalid &&
                !this.$v.userForm.$each[this.currentIndex].phoneNumber.$invalid
              ) {
                this.addParticipantPopup = true;
              }
            }
          } else {
            this.message = "Informe os dados do(a) cônjuge.";
            this.stableUnionArray.push("married");
            this.addBlankUser();
            this.userForm[this.currentIndex].marriedWith = {
              name: this.userForm[this.currentIndex - 1].fullName,
              cpf: this.userForm[this.currentIndex - 1].cpf
           }
          }
        }
        if (this.validStep) {
          //this.messageStep ++
          this.stop = false;
          this.show = false;
        }
      } else if (this.messageStep == 13) {
        //this.createProposal(this.$store.state.proposal);
        this.validateUserArray();
        //this.stop = false;
        //this.show = false;
      } else {
        this.stop = false;
        this.show = false;
      }
    },
    handleBack() {
      if (this.messageStep == 7) {
        this.messageStep = 4;
        this.stop = false;
        this.show = false;
        this.acquisitionForecast = null;
      }
      if (this.messageStep == 8) {
        this.messageStep = 6;
        this.stop = false;
        this.show = false;
        this.propertyLocation = "";
      }
      if (this.messageStep == 10) {
        this.messageStep = 7;
        this.stop = false;
        this.show = false;
        this.stepCounter = 1;
        this.stepMessage = "Sobre o Imóvel";
        //this.acquisitionForecastSelected = null
      }
      if (this.messageStep == 12) {
        this.messageStep = 9;
        this.stop = false;
        this.show = false;
        this.stepCounter = 2;
        this.stepMessage = "Entendendo o perfil do(s) participante(s).";
        //this.acquisitionForecastSelected = null
      }
      if (this.messageStep == 13) {
        this.messageStep = 12;
        this.stop = true;
        this.show = false;
        this.stepCounter = 3;
        this.stepMessage = "Cadastro do(s) participante(s).";
        //this.acquisitionForecastSelected = null
      }
      //this.messageStep--;
    },
    onError(error) {
      console.log("Error happened:", error);
    },
    onSuccess(token) {
      console.log("Succeeded:", token);
      // here you submit the form
      // this.$refs.form.submit()
      this.reCaptcha = true;
    },
    onExpired() {
      console.log("Expired");
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      // <-- extract fileExt -->
      let fileExt = files[0].name;
      fileExt = fileExt.substring(fileExt.lastIndexOf("."));

      // <-- check format -->
      if (this.allowedTypes.indexOf(fileExt) > -1) {
        for (let i = 0; i < files.length; i++) {
          const el = files[i];
          this.setFile(el);
        }
      } else {
        this.$store.commit("setErrorMessage", {
          color: "danger",
          text: "Ops... only .jpg,.pdf are allowed.",
        });
        return;
      }
    },
    async removeItem(index) {
      let storage = firebase.storage().ref();
      this.removeFile(index);
    },
    isPdf(fileObj) {
      let res = false;
      if (fileObj.name.substring(fileObj.name.lastIndexOf(".")) == ".pdf")
        res = true;
      return res;
    },
    async validateUserArray() {
      this.$v.$touch();
      if (this.$v.userForm.$invalid) {
        this.$store.commit("setErrorMessage", {
          color: "danger",
          text: "Ops... Corrija os dados necessários para prosseguir.",
        });
      } else if (this.duplicateCpfValidation) {
        this.loadingBtn = true;
        this.$store.commit("setErrorMessage", {
          color: "danger",
          text: "Já existe proposta para esse CPF.",
        });
      } else {
        this.validStep = true;
        await this.setUserProposals(this.userForm);
        this.show = false;
        this.messageStep = 15;
        // this.goNext = true;
        this.setParticipantArray(this.userForm);
        this.setComment(this.comments);
        this.createProposal(this.$store.state.proposal);
        this.stepMessage = "Proposta solicitada.";
        this.message =
          "Sua proposta foi enviada com sucesso! Confira as orientações enviadas para o e-mail cadastrado do proponente principal.";
      }
      // }
    },
    noStable() {
      this.showStableUnionPopup = false;
      this.addParticipantPopup = true;
    },
    async yesStable() {
      this.showStableUnionPopup = false;
      this.stableUnionArray.push("stable");
      this.message = "Informe os dados do(a) companheiro(a).";
      await this.addBlankUser();
       this.userForm[this.currentIndex].stableUnionWith = {
        name: this.userForm[this.currentIndex - 1].fullName,
        cpf: this.userForm[this.currentIndex - 1].cpf,
      };
    },
    addParticipantYes() {
      this.addParticipantPopup = false;
      this.message = "Informe os dados do(a) novo(a) participante.";
      this.stableUnionArray.push("participant");
      this.addBlankUser();
    },
    addParticipantNo() {
      this.message = "A hora é essa. Alinhando pequenos ajustes. ; )";
      this.stepMessage = "Aceite dos termos e condições";
      this.addParticipantPopup = false;
      this.messageStep++;
      this.show = false;
        window.scrollTo({
          top: 0,
          bottom: document.body.scrollHeight,
          behavior: "smooth",
        });
    },
    addBlankUser() {
      this.$v.$touch();
      if (this.$v.userForm.$invalid) {
        this.$store.commit("setErrorMessage", {
          color: "danger",
          text: "Ops... Corrija os dados necessários para prosseguir.",
        });
      } else {
        this.requiredMarit = false;
        this.currentIndex = this.currentIndex + 1;
        this.userForm.push({
          cpf: "",
          fullName: "",
          birthday: "",
          originOfIncomeSelected: null,
          occupation: "",
          salaryAmount: null,
          phoneNumber: "",
          maritalStatusField: null,
          email: "",
        });
      }
    },
    getChange() {
      if (this.userForm.length - 1 > this.currentIndex) {
        if (
          (this.stableUnionArray[this.currentIndex] == "married" &&
            this.userForm[this.currentIndex].maritalStatusField !=
              "Casado") ||
          (this.stableUnionArray[this.currentIndex] != "married" &&
            this.userForm[this.currentIndex].maritalStatusField == "Casado")
        ) {
          this.userForm = this.userForm.splice(0, this.currentIndex + 1);
        }
      }
    },
  },
};
</script>

<style>
div.fileuploader.fileuploader-theme-dragdrop {
  margin: 0 0 16px 0;
}
.fileuploader {
  max-width: 420px;
  margin: 15px;
}
.footer {
  position: absolute;
  bottom: 0;
  top: auto;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
}

.teste2 {
  /* height: 718px;*/
  pointer-events: none;
  position: absolute;
  position: fixed;
  right: 0px;
  /* top: 133px; */
  bottom: 0;
  width: 568px;
}

.teste3 {
  height: 80vh;
  position: absolute;
  width: 568px;
  bottom: 0;
  right: 10px;
  z-index: inherit;
}
.infos {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.dropzone {
  border: 2px dashed rgba(49, 58, 70, 0.3);
  background: #ffffff;
  border-radius: 6px;
  cursor: pointer;
  min-height: 150px;
  padding: 20px;
}

.dropzone h3,
.mute {
  color: #6c757d;
}

.dropzone .dz-message {
  text-align: center;
  margin: 2rem 0;
}

.dropzone .dz-message .dz-started .dz-message {
  display: none;
}

.pre-footer {
  position: fixed;
  bottom: 0px;
  left: 0;
  min-height: 60px;
  line-height: 60px;
  /* border-top: 1px solid #5e5e5e; */
  z-index: 2;
  /*background-color: #ffffff;*/
  width: calc(100% - 280px);
}
.display-4-title {
  font-weight: 500;
  line-height: 1.2;
}
.display-4-label {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
}
/*input.form-control {
  background-color: transparent;
  font-size: 1.5rem;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
}
input.form-control:focus {
  background-color: transparent;
  color: #0069d9;
  transition: none;
}
select.custom-select {
  background-color: transparent;
  font-size: 1.5rem;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
}
select.custom-select:focus {
  background-color: transparent;
  color: #0069d9;
  transition: none;
}*/
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.teste {
  background-attachment: scroll;
  background-color: #039be5;
  /* background-image: url(images/grain@2x.png); */
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% 100%;
  flex: 0 0 320px;
  height: 100%;
  /*min-height: 851px;*/
  position: absolute;
  position: fixed;
  right: 0;
  top: 0;
  width: 320px;
  z-index: -1;
}

.teste2 {
  /* height: 718px;*/
  pointer-events: none;
  position: absolute;
  position: fixed;
  right: 0px;
  /* top: 133px; */
  bottom: 0;
  width: 568px;
}

.teste3 {
  height: 80vh;
  position: absolute;
  width: 568px;
  bottom: 0;
  right: 10px;
  z-index: inherit;
}
</style>
