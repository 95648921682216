import Vue from 'vue'
import Vuex from 'vuex'
var firebase = require('firebase/app');
// import firebase from 'firebase/app'
import db from '../main';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    participantsArray: [],
    file: [],
    dismissCountDown: 0,
    errorMessage: {
      color: '',
      text: ''
    },
    dismissSecs: 4,
    proposals: [],
    proposal: {
      cpf: '',
      fullName: '',
      pis: '',
      occupation: '',
      salaryAmount: '',
      originOfIncomeSelected: null,
      phoneNumber: '',
      maritalStatusField: '',
      email: '',
      fgtsCheck: false,
      propertyCategory: null,
      propertyType: null,
      propertyValue: 0,
      notaryExpenses: false,
      intendedFinancingValue: 0,
      period: '',
      operationModality: null,
      amortizationSystem: null,
      indexer: null,
      propertyLocation: {},
      comments: '',
      participantItems: '',
      acquisitionForecast: "",
      note: '',
      steps: {
        note: '',
        riskAssessment: {
          actived: false,
          badgeColor: '',
          dueDate: '',
          note: '',
          situation: '',
          status: ''
        },
        engineering: {
          actived: false,
          badgeColor: '',
          dueDate: '',
          note: '',
          situation: '',
          status: ''
        },
        forms: {
          actived: false,
          badgeColor: '',
          dueDate: '',
          note: '',
          situation: '',
          status: ''
        },
        fgts: {
          actived: false,
          badgeColor: '',
          dueDate: '',
          note: '',
          situation: '',
          status: ''
        },
        dossier: {
          actived: false,
          badgeColor: '',
          dueDate: '',
          note: '',
          situation: '',
          status: ''
        },
        contract: {
          actived: false,
          badgeColor: '',
          dueDate: '',
          note: '',
          situation: '',
          status: ''
        }
      }
    },
  },
  mutations: {
    setFile(state, payload) {
      state.file.push({ file: payload, name: payload.name, type: payload.type })
    },
    removeFile(state, payload) {
      state.file.splice(payload, 1);
    },
    setErrorMessage(state, payload) {
      state.errorMessage = payload;
      state.dismissCountDown = state.dismissSecs;
    },
    setParticipantArray(state, payload) {
      state.participantsArray = payload
    },
    setProposal(state, payload) {
      state.proposal = payload;
    },
    setBlankFileArray(state, payload) {
      state.file = payload
    },
    setPropertyDetails(state, payload) {
      state.proposal.propertyType = payload.propertyTypeSelected
      state.proposal.propertyCategory = payload.propertyCategorySelected
      state.proposal.propertyValue = payload.propertyValue
      state.proposal.notaryExpenses = payload.notaryExpenses
      state.proposal.propertyLocation = payload.propertyLocation
    },
    setUserProposals(state, payload) {
      state.proposal.cpf = payload[0].cpf
      state.proposal.fullName = payload[0].fullName
      state.proposal.originOfIncomeSelected = payload[0].originOfIncomeSelected
      state.proposal.salaryAmount = payload[0].salaryAmount
      // state.proposal.pis = payload[0].pis
      state.proposal.occupation = payload[0].occupation
      state.proposal.phoneNumber = payload[0].phoneNumber
      state.proposal.maritalStatusField = payload[0].maritalStatusField
      state.proposal.email = payload[0].email
      // payload.forEach(form => {
      //   if (form.fgtsCheck === true) state.proposal.fgtsCheck = true
      // })
      if (payload.length > 1) {
        state.proposal.participants = payload.slice(1, payload.length)
      }
    },
    setAcquisitionForecast(state, payload) {
      state.proposal.acquisitionForecast=payload
    },
    setDismissCountDown(state, payload) {
      state.dismissCountDown = payload;
    },
    setParticipantItems(state, payload) {
      state.proposal.participantItems = payload;
    },
    setIntendedFinancing(state, payload) {
      state.proposal.intendedFinancingValue = payload;
    },
    setComment(state, payload) {
      state.proposal.comments = payload
    }
  },
  actions: {
    anonymouslySignIn(){
      firebase.auth().signInAnonymously()
      .then(async () => {
        // Signed in..    
        console.log('sign in');
      })
      .catch((error) => {
        console.log(error);
      });
    },
    async createProposal({ commit, dispatch }, payload) {
        firebase.auth().onAuthStateChanged(async (user) => {
          if (user) {
            try {
              const doc = await db.collection('proposals').add({
                bankBranch: '',
                cpf: payload.cpf,
                name: payload.fullName,
                pis: payload.pis,
                occupation: payload.occupation,
                phoneNumber: payload.phoneNumber,
                maritalStatus: payload.maritalStatusField,
                email: payload.email,
                fgts: payload.fgtsCheck,
                originOfIncomeSelected: payload.originOfIncomeSelected,
                salaryAmount: payload.salaryAmount,
                propertyType: payload.propertyType,
                propertyCategory: payload.propertyCategory,
                propertyValue: payload.propertyValue,
                intendedFinancingValue: payload.intendedFinancingValue,
                period: payload.period,
                amortizationSystem: payload.amortizationSystem,
                indexer: payload.indexer,
                propertyLocation: payload.propertyLocation,
                notaryExpenses: payload.notaryExpenses ? payload.notaryExpenses : false,
                created: new Date(),
                participants: payload.participants ? payload.participants : [],
                approvedFinancingAmount: 0,
                engineeringEvaluation: 0,
                operationModality: null,
                operationValue: 0,
                fgtsResourcesValue: 0,
                comments: payload.comments,
                participantItems: payload.participantItems,
                acquisitionForecast: payload.acquisitionForecast,
                steps: {
                  note: '',
                  riskAssessment: {
                    actived: false,
                    situation: '',
                    status: '',
                    dueDate: '',
                    badgeColor: 'light',
                    note: ''
                  },
                  engineering: {
                    actived: false,
                    situation: '',
                    status: '',
                    dueDate: '',
                    badgeColor: 'light',
                    note: ''
                  },
                  forms: {
                    actived: false,
                    situation: '',
                    status: '',
                    dueDate: '',
                    badgeColor: 'light',
                    note: ''
                  },
                  fgts: {
                    actived: false,
                    situation: '',
                    status: '',
                    dueDate: '',
                    badgeColor: 'light',
                    note: ''
                  },
                  dossier: {
                    actived: false,
                    situation: '',
                    status: '',
                    dueDate: '',
                    badgeColor: 'light',
                    note: ''
                  },
                  contract: {
                    actived: false,
                    situation: '',
                    status: '',
                    dueDate: '',
                    badgeColor: 'light',
                    note: ''
                  }
                },

                updated: new Date(),
                // created_by: user.email,
                actived: true,
                expected: false,
                progressValue: 0,
                progressValue2: 0,

              })
              //axios.post("https://us-central1-parceiro-caixa.cloudfunctions.net/sendNotificationOfNewPorposal")
              //console.log("Proposta criada com sucesso!");
              commit('setProposal', { name: payload, id: doc.id })
              await dispatch('saveDocuments', doc.id)
              //this.app.router.push('/habitacao');

            } catch (error) {
              console.log(error)
            }
            commit('setErrorMessage', {
              color: 'success',
              text: 'Proposta criada com sucesso!'
            });
          } else {
            // User is signed out
            // ...
          }
        });


    },
    async saveDocuments({ state, commit }, payload) {
      try {
        let storage = firebase.storage().ref()
        state.file.forEach(async (file) => {
          await storage.child(`${payload}/${file.name}`).put(file.file)
        })
        commit('setBlankFileArray', [])
        // router.push({name: 'welcome'})
      } catch (error) {
        commit('setErrorMessage', {
          color: 'danger',
          text: 'Getting some error while save documents!'
        });
      }
    },
    dismissed({ commit }) {
      commit('setDismissCountDown', 0);
    },
  },
  modules: {
  }
})
